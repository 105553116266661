import { useEffect, useState } from "react";
import styles from "./action.module.css";
import classNames from "classnames";

import translations from "../const";

const BigerText = (props) => {
	const [active, setActive] = useState(false);
	const [stylePosition, setStylePosition] = useState(-1);

	const [lang, setLang] = useState('es-ES');

	useEffect(() => {
		setLang(document.documentElement.getAttribute('lang') || 'es-ES');
	}, []);

	useEffect(() => {
		if (active) {
		} else {
		}
	}, [active]);

	const _next = () => {
		var _newposition = stylePosition + 1;
		setStylePosition(_newposition);

		if (_newposition >= 3) {
			_newposition = -1;
			setStylePosition(_newposition);
		}

		if (_newposition === -1) {
			const elements = document.querySelectorAll(
				"p, h1, h2, h3, h4, h5, h6, a, b, strong"
			);
			elements.forEach((element) => {
				var originalFontSize = element.getAttribute("data-fontsize");
				element.style.fontSize = originalFontSize + "px";
			});
		} else {
			const elements = document.querySelectorAll(
				"p, h1, h2, h3, h4, h5, h6, a, b, strong"
			);
			elements.forEach((element) => {
				const currentFontSize = window.getComputedStyle(element).fontSize;
				const currentFontSizeValue = parseFloat(currentFontSize);
				const newFontSize = currentFontSizeValue + 3 + "px";
				element.style.fontSize = newFontSize;
			});
		}

		if (_newposition === -1) {
			setActive(false);
		} else {
			setActive(true);
		}
	};

	useEffect(() => {
		const elements = document.querySelectorAll(
			"p, h1, h2, h3, h4, h5, h6, a, b, strong"
		);
		elements.forEach((element) => {
			const currentFontSize = window.getComputedStyle(element).fontSize;
			const currentFontSizeValue = parseFloat(currentFontSize);
			element.setAttribute("data-fontsize", currentFontSizeValue);
		});
	}, []);

	return (
		<button
			onClick={() => _next()}
			className={classNames(styles.btn, {
				[styles.active]: active,
			})}
		>
			<div className={styles.active_label}>
				<svg height="16px" viewBox="0 -960 960 960" width="16px" fill="white">
					<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
				</svg>
			</div>
			<svg height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
				<path d="M280-160v-520H80v-120h520v120H400v520H280Zm360 0v-320H520v-120h360v120H760v320H640Z" />
			</svg>
			<ul className={styles.steep}>
				{new Array(3).fill(0).map((_, index) => (
					<li
						key={index}
						className={classNames({
							[styles.active]: index <= stylePosition && stylePosition !== -1,
						})}
					></li>
				))}
			</ul>
			<span>{translations[lang].biger_text}</span>
		</button>
	);
};

export default BigerText;
