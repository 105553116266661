import { useEffect, useState } from "react";
import styles from "./action.module.css";
import classNames from "classnames";
import translations from "../const";

const LetterSpacing = (props) => {
	const [active, setActive] = useState(false);
	const [stylePosition, setStylePosition] = useState(-1);
	const [lang, setLang] = useState('es-ES');

	useEffect(() => {
		setLang(document.documentElement.getAttribute('lang') || 'es-ES');
	}, []);

	useEffect(() => {
		if (active) {
		} else {
		}
	}, [active]);

	const _next = () => {
		var _newposition = stylePosition + 1;
		setStylePosition(_newposition);

		if (_newposition >= 3) {
			_newposition = -1;
			setStylePosition(_newposition);
		}

		if (_newposition === -1) {
			document.body.classList.remove(styles.letter_spacing_0);
			document.body.classList.remove(styles.letter_spacing_1);
			document.body.classList.remove(styles.letter_spacing_2);
		} else {
			document.body.classList.add(styles[`letter_spacing_${_newposition}`]);
		}

		if (_newposition === -1) {
			setActive(false);
		} else {
			setActive(true);
		}
	};

	return (
		<button
			onClick={() => _next()}
			className={classNames(styles.btn, {
				[styles.active]: active,
			})}
		>
			<div className={styles.active_label}>
				<svg height="16px" viewBox="0 -960 960 960" width="16px" fill="white">
					<path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
				</svg>
			</div>
			<svg height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
				<path d="M240-80 80-240l160-160 57 56-64 64h494l-63-64 56-56 160 160L720-80l-57-56 64-64H233l63 64-56 56Zm36-360 164-440h80l164 440h-76l-38-112H392l-40 112h-76Zm138-176h132l-64-182h-4l-64 182Z" />
			</svg>
			<ul className={styles.steep}>
				{new Array(3).fill(0).map((_, index) => (
					<li
						key={index}
						className={classNames({
							[styles.active]: index <= stylePosition && stylePosition !== -1,
						})}
					></li>
				))}
			</ul>
			<span>{translations[lang].letter_spacing}</span>
		</button>
	);
};

export default LetterSpacing;
