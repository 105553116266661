import { useState } from "react";
import style from "./index.module.css";
import classNames from "classnames";
import Links from "./components/Links";
import BigerText from "./components/BigerText";
import LetterSpacing from "./components/LetterSpacing";
import LineHeight from "./components/LineHeight";
import Cursor from "./components/Cursor";
import { useEffect } from "react";
import translations from "./const";


const App = (props) => {
	const [open, setOpen] = useState(false);
	const [lang, setLang] = useState('es-ES');


	useEffect(() => {
		setLang(document.documentElement.getAttribute('lang') || 'es-ES');
	}, []);

	

	const _toogle = () => {
		setOpen(!open);
	};
	return (
		<div className={style.main_app}>
			<button onClick={_toogle} className={style.btn_action}>
				<svg height="30px" viewBox="0 -960 960 960" width="30px" fill="black">
					<path d="M480-800q-33 0-56.5-23.5T400-880q0-33 23.5-56.5T480-960q33 0 56.5 23.5T560-880q0 33-23.5 56.5T480-800ZM360-200v-480q-60-5-122-15t-118-25l20-80q78 21 166 30.5t174 9.5q86 0 174-9.5T820-800l20 80q-56 15-118 25t-122 15v480h-80v-240h-80v240h-80ZM320 0q-17 0-28.5-11.5T280-40q0-17 11.5-28.5T320-80q17 0 28.5 11.5T360-40q0 17-11.5 28.5T320 0Zm160 0q-17 0-28.5-11.5T440-40q0-17 11.5-28.5T480-80q17 0 28.5 11.5T520-40q0 17-11.5 28.5T480 0Zm160 0q-17 0-28.5-11.5T600-40q0-17 11.5-28.5T640-80q17 0 28.5 11.5T680-40q0 17-11.5 28.5T640 0Z" />
				</svg>
			</button>
			<div
				className={classNames(style.modal, {
					[style.active]: open,
				})}
			>
				<div className={style.modal_header}>
					<h1 className={style.title}>
						{translations[lang].title}
					</h1>
					<button onClick={_toogle} className={style.modal_button_close}>
						<svg
							height="18px"
							viewBox="0 -960 960 960"
							width="18px"
							style={{
								fill: "white",
								minWidth: "18px",
								minHeight: "18px",
							}}
						>
							<path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
						</svg>
					</button>
				</div>
				<div className={style.modal_content}>
					<div className={style.actions}>
						<Links />
						<BigerText />
						<LetterSpacing />
						<LineHeight />
						<Cursor />
					</div>
				</div>
			</div>
		</div>
	);
};

export default App;
