const translations = {
	'es-ES': {
        title : "Menú de accesibilidad",
        highlight_links : "Resaltar enlaces",
        biger_text : "Texto más grande",
        letter_spacing : "Espaciado de texto",
        line_height : "Altura de líneas",
        cursor : "Cursor"
    },
	'en-US': {
        title : "Accessibility Menu",
        highlight_links : "Highlight Links",
        biger_text : "Bigger Text",
        letter_spacing : "Letter Spacing",
        line_height : "Line Height",
        cursor : "Cursor"
    }
};


export default translations;